$.fn.waterfallPlugin = function (options) {
	var pluginName = "waterfallPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.

	var sequenzes;
	var sliders;
	var flips = null;
	var navigationBar = null;

	window.onscroll = function() {
		setOpacity();
		setSliderListener();
	}

	initializeYouTubeMessageListener();
	initializeYouTubePlayButtons();

	return this.each(function () {
		sequenzes = document.querySelectorAll('.js-waterfall-sequenz');
		sliders = document.querySelectorAll('.js-waterfall-sequenz .scroll-slider');
		flips = document.querySelectorAll(".js-waterfall-flip-front");

		setOpacity();
		setupScrollSlider();
		setupNavigationBar();
		setupFlips();
	});

	function initializeYouTubeMessageListener() {
		window.addEventListener('message', function (e) {
			if (e.origin !== 'https://www.youtube-nocookie.com' || e.data === undefined) return;

			var data = JSON.parse(e.data);

			if (data.event === 'initialDelivery') {
				if (data.info.playerState === 1 || data.info.playerState === 3) {
					var button = document.querySelector('[data-yt-iframe="' + data.id + '"]');
					if (button) {
						button.setAttribute('aria-pressed', 'true');
						button.removeAttribute('data-yt-iframe');
					}
				}
			}
		});
	}

	function initializeYouTubePlayButtons() {
		var buttons = document.querySelectorAll('.js-waterfall-sequenz-video-start-stop');

		buttons.forEach(function (button) {
			var iframe = button.nextElementSibling.querySelector('iframe');
			iframe.id = 'yt-iframe-' + button.nextElementSibling.dataset.videoId + '-' + Math.random().toString(36).substring(2, 6);

			button.setAttribute('data-yt-iframe', iframe.id);
			button.disabled = true;

			iframe.addEventListener('load', function () {
				setTimeout(function () {
					iframe.contentWindow.postMessage(JSON.stringify({
						event: 'listening',
						id: iframe.id,
						channel: 'widget',
					}), '*');

					button.disabled = false;
				}, 1000);
			});

			button.addEventListener('click', function () {
				if (this.getAttribute('aria-pressed') === 'true') {
					this.setAttribute('aria-pressed', 'false');

					iframe.contentWindow.postMessage(JSON.stringify({
						event: 'command',
						func: 'pauseVideo',
					}), '*');
				} else {
					this.setAttribute('aria-pressed', 'true');

					iframe.contentWindow.postMessage(JSON.stringify({
						event: 'command',
						func: 'playVideo',
					}), '*');
				}
			});
		});
	}

	function numberOfShownSlides() {
		var windowWidth = $(window).width(),
			showSlides = windowWidth > 991 ? 3 : 1;

		return showSlides;
	}

	function setupScrollSlider() {
		var showSlides = numberOfShownSlides();
		$('.scroll-slider').each(function() {
			var $slider = $(this),
				$sliderParentElem = $slider.parent(),
				sliderWidth = $sliderParentElem.width(),
				highestSlideHeight = 0;

			$slider.css("width",sliderWidth);

			$slider.find('.scroll-slider__slide').each(function(){
				$(this).css("width", sliderWidth / showSlides - 20);

				if($(this).outerHeight() > highestSlideHeight) {
					highestSlideHeight = $(this).outerHeight();
				}
			});

			$slider.css("height", highestSlideHeight);
			$sliderParentElem.css("height", highestSlideHeight * 3);
		});
	}

	function setOpacity () {
		for(var i = 0; i < sequenzes.length; i++) {
			var sequenz = sequenzes[i];
			var sequenzBg = sequenz.querySelector('.js-waterfall-sequenz__bg');
			var gradientDisabled = sequenzBg.dataset.gradientDisabled;
			if (gradientDisabled !== undefined && gradientDisabled !== null && gradientDisabled === "true") {
				gradientDisabled = true;
			} else {
				gradientDisabled = false;
			}
			var is = getElementVisibility(sequenz);

			// resetFlipListener(sequenz);

			if (is.topVisible) {
				var opacity = (1 - (sequenz.getBoundingClientRect().top / window.innerHeight)) * 2;
				sequenz.dataset.isUnder = false;
				if (!gradientDisabled) {
					sequenzBg.style.opacity = opacity;
				} else {
					sequenzBg.style.opacity = 1;
				}

				loadBackground(sequenz, sequenzBg);
				if (i < (sequenz.length - 1)) {
					loadBackground(sequenz[i + 1], sequenz[i + 1].querySelector('.js-waterfall-sequenz__bg')); // Also preload next for nicer transitions
				}
			} else if (is.topUnderViewport && (sequenz.dataset.isUnder == "false")) {
				sequenz.dataset.isUnder = true;
				if (!gradientDisabled) {
					sequenzBg.style.opacity = 0;
				} else {
					sequenzBg.style.opacity = 1;
				}
			}
		}
	}

	function loadBackground(sequenz, sequenzBg) {
		if (sequenzBg.dataset.imgUrl) {
			sequenz.classList.remove('js-lazy-waterfall-content');
			sequenzBg.style.backgroundImage = 'url(\'' + sequenzBg.dataset.imgUrl + '\')';
			sequenzBg.removeAttribute('data-img-url');
		}

		if (!sequenzBg.classList.contains('video-disabled') && sequenzBg.dataset.videoUrl) {
			var iframe = sequenzBg.querySelector('iframe');
			sequenz.classList.remove('js-lazy-waterfall-content');
			if (!iframe.src) {
				iframe.src = sequenzBg.dataset.videoUrl;
			}
		}
	}

	function setSliderListener () {
		for(var i = 0; i < sliders.length; i++) {
			
			var slider = sliders[i];
			var is = getElementVisibilityWithOffset(slider, 40);
			
			if (is.topZeroViewport) {
				var sliderInner = slider.querySelector('.scroll-slider__inner');
				var sliderInnerWidth = sliderInner.getBoundingClientRect().width;

				var sliderOuter = slider.closest('.scroll-slider__outer');
				var sliderOuterBoundings = sliderOuter.getBoundingClientRect();
				var sliderOuterScrollTop = sliderOuterBoundings.top * -1;

				var scrollBase = sliderOuterBoundings.height - window.innerHeight;
				var scrollPercentage = (100 / scrollBase * sliderOuterScrollTop) / 100;
				var translateX = sliderInnerWidth * scrollPercentage;
				
				if (translateX >= 0 && translateX <= sliderInnerWidth - sliderOuterBoundings.width) {
					sliderInner.setAttribute("style", "transform: translateX(-" + translateX + "px);");
				}
			}
		}
	}

	function getElementVisibility (elem) {
		return getElementVisibilityWithOffset(elem, 0);
	}

	function getElementVisibilityWithOffset (elem, offset) {
		var bounding = elem.getBoundingClientRect();
		var is = {};
		
		is.topVisible = (bounding.top - offset) < (window.innerHeight || document.documentElement.clientHeight) && (bounding.top - offset) >= 0;
		is.topUnderViewport = (bounding.top - offset) > (window.innerHeight || document.documentElement.clientHeight);
		is.topZeroViewport = (bounding.top - offset) == 0;

		is.bottom = bounding.bottom < (window.innerHeight || document.documentElement.clientHeight) && bounding.bottom >= 0;

		//is.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
		//is.any = is.topVisible || is.left || is.bottom || is.right;
		//is.all = is.topVisible && is.left && is.bottom && is.right;
	
		return is;
	};

	function setupNavigationBar() {
		navigationBar = document.getElementById("waterfall-nav-bar");
		if (navigationBar !== undefined && navigationBar !== null) {
			var header = document.querySelector("header");
			header.appendChild(navigationBar);
		}
	}

	function setupFlips() {
		for (var index = 0; index < flips.length; ++index) {
			addFlipFrontListener(flips[index]);
		}
	}

	function addFlipFrontListener(flip) {
		if (flip !== undefined && flip !== null) {
			resetFlipFrontListener(flip);
			flip.addEventListener("click", flipFrontClick);
		}
	}

	function resetFlipFrontListener(flip) {
		if (flip !== undefined && flip !== null) {
			flip.removeEventListener("click", flipFrontClick);
		}
	}

	function flipFrontClick(event) {
		var flipFront = event.currentTarget;
		if (flipFront !== undefined && flipFront !== null) {
			var flipBack = document.getElementById(flipFront.dataset.flipBackId);
			var flipFrontContainer = document.getElementById(flipFront.dataset.flipFrontContainerId);
			var flipBackContainer = document.getElementById(flipFront.dataset.flipBackContainerId);
			if (flipBack !== undefined && flipBack !== null) {
				resetFlipFrontListener(flipFront);
				addFlipBackListener(flipBack);
				flipFront.classList.remove("waterfall-flip-front-non-rotated");
				flipFront.classList.add("waterfall-flip-front-rotated");
				flipBack.classList.remove("waterfall-flip-back-non-rotated");
				flipBack.classList.add("waterfall-flip-back-rotated");
				
				flipFrontContainer.classList.remove("waterfall-flip-stacked-primary-click-receiver");
				flipFrontContainer.classList.add("waterfall-flip-stacked-secondary-click-receiver");
				flipBackContainer.classList.add("waterfall-flip-stacked-primary-click-receiver");
				flipBackContainer.classList.remove("waterfall-flip-stacked-secondary-click-receiver");
			}
		}
	}

	function addFlipBackListener(flipBack) {
		if (flipBack !== undefined && flipBack !== undefined) {
			resetFlipBackListener(flipBack);
			flipBack.addEventListener("click", flipBackClick);
		}
	}

	function resetFlipBackListener(flipBack) {
		if (flipBack !== undefined && flipBack !== undefined) {
			flipBack.removeEventListener("click", flipBackClick);
		}
	}

	function flipBackClick(event) {
		var flipBack = event.currentTarget;
		if (flipBack !== undefined && flipBack !== null) {
			var flipFront = document.getElementById(flipBack.dataset.flipFrontId);
			var flipFrontContainer = document.getElementById(flipBack.dataset.flipFrontContainerId);
			var flipBackContainer = document.getElementById(flipBack.dataset.flipBackContainerId);
			if (flipFront !== undefined && flipFront !== null) {
				resetFlipBackListener(flipBack);
				addFlipFrontListener(flipFront);
				flipFront.classList.remove("waterfall-flip-front-rotated");
				flipFront.classList.add("waterfall-flip-front-non-rotated");
				flipBack.classList.remove("waterfall-flip-back-rotated");
				flipBack.classList.add("waterfall-flip-back-non-rotated");
				
				flipFrontContainer.classList.add("waterfall-flip-stacked-primary-click-receiver");
				flipFrontContainer.classList.remove("waterfall-flip-stacked-secondary-click-receiver");
				flipBackContainer.classList.remove("waterfall-flip-stacked-primary-click-receiver");
				flipBackContainer.classList.add("waterfall-flip-stacked-secondary-click-receiver");
			}
		}
	}
};
